exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bespoke-business-range-js": () => import("./../../../src/pages/bespoke/business-range.js" /* webpackChunkName: "component---src-pages-bespoke-business-range-js" */),
  "component---src-pages-bespoke-js": () => import("./../../../src/pages/bespoke.js" /* webpackChunkName: "component---src-pages-bespoke-js" */),
  "component---src-pages-bespoke-materials-js": () => import("./../../../src/pages/bespoke/materials.js" /* webpackChunkName: "component---src-pages-bespoke-materials-js" */),
  "component---src-pages-bespoke-process-js": () => import("./../../../src/pages/bespoke/process.js" /* webpackChunkName: "component---src-pages-bespoke-process-js" */),
  "component---src-pages-bespoke-projects-js": () => import("./../../../src/pages/bespoke/projects.js" /* webpackChunkName: "component---src-pages-bespoke-projects-js" */),
  "component---src-pages-care-js": () => import("./../../../src/pages/care.js" /* webpackChunkName: "component---src-pages-care-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-catalog-js": () => import("./../../../src/pages/catalog.js" /* webpackChunkName: "component---src-pages-catalog-js" */),
  "component---src-pages-collaborations-js": () => import("./../../../src/pages/collaborations.js" /* webpackChunkName: "component---src-pages-collaborations-js" */),
  "component---src-pages-collections-collections-js": () => import("./../../../src/pages/collections/collections.js" /* webpackChunkName: "component---src-pages-collections-collections-js" */),
  "component---src-pages-collections-designers-js": () => import("./../../../src/pages/collections/designers.js" /* webpackChunkName: "component---src-pages-collections-designers-js" */),
  "component---src-pages-collections-distribution-js": () => import("./../../../src/pages/collections/distribution.js" /* webpackChunkName: "component---src-pages-collections-distribution-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-collections-materials-js": () => import("./../../../src/pages/collections/materials.js" /* webpackChunkName: "component---src-pages-collections-materials-js" */),
  "component---src-pages-collections-products-js": () => import("./../../../src/pages/collections/products.js" /* webpackChunkName: "component---src-pages-collections-products-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-account-change-password-js": () => import("./../../../src/pages/my-account/change-password.js" /* webpackChunkName: "component---src-pages-my-account-change-password-js" */),
  "component---src-pages-my-account-edit-account-js": () => import("./../../../src/pages/my-account/edit-account.js" /* webpackChunkName: "component---src-pages-my-account-edit-account-js" */),
  "component---src-pages-my-account-js": () => import("./../../../src/pages/my-account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-my-account-logged-in-js": () => import("./../../../src/pages/my-account/logged-in.js" /* webpackChunkName: "component---src-pages-my-account-logged-in-js" */),
  "component---src-pages-my-account-lost-password-js": () => import("./../../../src/pages/my-account/lost-password.js" /* webpackChunkName: "component---src-pages-my-account-lost-password-js" */),
  "component---src-pages-my-account-registration-js": () => import("./../../../src/pages/my-account/registration.js" /* webpackChunkName: "component---src-pages-my-account-registration-js" */),
  "component---src-pages-my-account-welcome-js": () => import("./../../../src/pages/my-account/welcome.js" /* webpackChunkName: "component---src-pages-my-account-welcome-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-templates-collaboration-js": () => import("./../../../src/templates/collaboration.js" /* webpackChunkName: "component---src-templates-collaboration-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-designer-js": () => import("./../../../src/templates/designer.js" /* webpackChunkName: "component---src-templates-designer-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-press-js": () => import("./../../../src/templates/press.js" /* webpackChunkName: "component---src-templates-press-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

