import axios from 'axios'

const body = document.querySelector('body')

const checkHeaderParams = () => {
  const trigger = document.querySelector('.menu-bottom-trigger')
  if (trigger) {
    body.classList.add('page-has-trigger')
    const darkTransparentNoChange = document.querySelector(
      '.dark-transparent-no-change',
    )
    if (darkTransparentNoChange) {
      body.classList.add('trigger-in-view')
      body.classList.remove('white')
    } else {
      if (window.scrollY < trigger.offsetTop) {
        body.classList.add('trigger-in-view')
        body.classList.add('white')
      } else {
        body.classList.remove('trigger-in-view')
        body.classList.remove('white')
      }
    }
  } else {
    body.classList.remove('page-has-trigger')
    body.classList.remove('trigger-in-view')
    body.classList.remove('white')
  }

  const title = document.querySelector('h1.page-title.placeholder')
  if (title) {
    body.classList.add('page-has-title')
    if (window.scrollY < title.offsetTop) {
      body.classList.remove('title-out-view')
    } else {
      body.classList.add('title-out-view')
    }
  } else {
    body.classList.remove('page-has-title')
    body.classList.remove('title-out-view')
  }
}

const updateOtherLanLink = () => {
  let otherLanLink =
    process.env.GATSBY_SITE_URL_OTHER +
    window.location.pathname +
    window.location.search
  if (otherLanLink.endsWith('/')) {
    otherLanLink = otherLanLink.slice(0, -1)
  }
  document.querySelector('.lan-other').href = otherLanLink
}

const onRefresh = () => {
  updateOtherLanLink()
  body.classList.add('transitioning')
  body.classList.remove('title-out-view')
  setTimeout(() => {
    checkHeaderParams()
    body.classList.remove('transitioning')
  }, 1)
}

// exports.onInitialClientRender = () => {
//   onRefresh();
// }

export const onRouteUpdate = ({ location }) => {
  if (!sessionStorage.getItem('userLocationApproved')) {
    // console.log('no session')
    checkUserLocationApproved()
  } else {
    // console.log('got session')
    // console.log(sessionStorage.getItem('userLocationApproved'))
  }
  setLastPath(location.pathname)
  onRefresh()
}

const setLastPath = (pathname) => {
  if (!pathname.startsWith('/my-account')) {
    sessionStorage.setItem('lastPath', pathname)
  }
}

const checkUserLocationApproved = () => {
  axios({
    method: 'get',
    url: `https://stellar.lanternbox.com/php/countryDetect.php`,
  }).then((response) => {
    const userCountryCode = response?.data
    // console.log(userCountryCode)

    const restrictCountryCodes = ['CN', 'HK', 'TW']

    if (!userCountryCode) {
      // console.log('error getting country code')
      sessionStorage.setItem('userLocationApproved', 'no')
    } else {
      if (!restrictCountryCodes.includes(userCountryCode)) {
        // got a code and it's not restricted
        // console.log('country approved')
        sessionStorage.setItem('userLocationApproved', 'yes')
      } else {
        // got a code and it is restricted
        // console.log('country not approved')
        sessionStorage.setItem('userLocationApproved', 'no')
      }
    }
  })
}

window.addEventListener('scroll', checkHeaderParams)

// exports.onRouteUpdate = ({location, action}) => {
//     checkForNoHeaderPage(location.pathname);
//   }

// const header = document.getElementById('header');
// if (header) {
//   header.removeAttribute('class', 'menu-panel-open');
// }

// exports.onInitialClientRender = () => {
//   checkForTrigger();
// }

// const checkForTrigger = () => {
//   const trigger = document.querySelector('.menu-bottom-trigger');
//   if (trigger) {
//   } else {
//   }
// }

// const checkForNoHeaderPage = (pathname) => {
//   if (body) {
//     if (
//       pathname == '/' ||
//       pathname == '/story' ||
//       pathname.includes('/collections/')  // item only
//     ) {
//       body.classList.add('bg-no-header-page')
//     } else {
//       body.classList.remove('bg-no-header-page')
//     }
//   }
// }

// title pages:
// - products
// - product item
// - collections
// - collection item (white)
